<template>
  <div>
    <div
      class="bg-black fixed transition transition-all transition-medium top-0 left-0 h-screen w-full z-30"
      :class="{
        'opacity-0 invisible': !value,
        'opacity-25 visible': value,
      }"
    />
    <div
      class="bg-white rounded-lg transition transition-all transition-medium shadow-xl fixed left-0 top-0 p-6 z-40"
      :class="{
        'opacity-25 invisible': !value,
        'opacity-100 visible': value,
      }"
      :style="`top: ${value ? '50%' : '60%'}; left: 50%; transform: translate(-50%, -50%)`"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
