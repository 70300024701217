<template>
  <div class="w-full sm:w-80 mx-auto mb-32">
    <TextField
      v-model="model"
      type="number"
      placeholder="JJJJ"
      :min="1840"
      :max="maxYear"
      required
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      model: null,
      maxYear: 2019,
    };
  },

  watch: {
    model(newVal) {
      this.$emit('input', newVal);
    },
  },

  mounted() {
    this.maxYear = new Date().getFullYear();
    this.model = this.value > 0 ? this.value : null;
  },
};
</script>
