var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bg-black fixed transition transition-all transition-medium top-0 left-0 h-screen w-full z-30",class:{
      'opacity-0 invisible': !_vm.value,
      'opacity-25 visible': _vm.value,
    }}),_c('div',{staticClass:"bg-white rounded-lg transition transition-all transition-medium shadow-xl fixed left-0 top-0 p-6 z-40",class:{
      'opacity-25 invisible': !_vm.value,
      'opacity-100 visible': _vm.value,
    },style:(`top: ${_vm.value ? '50%' : '60%'}; left: 50%; transform: translate(-50%, -50%)`)},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }