<template>
  <div
    class="fixed lg:relative flex bottom-0 w-full left-0 z-20 px-4 lg:px-0 py-2 bg-white lg:bg-transparent shadow-xl lg:shadow-none
    border-gray-400 border-t-2 lg:border-none lg:mt-2"
  >
    <div
      class="container flex"
      :class="{
        'justify-between sm:justify-end': activeStep > 0,
        'justify-end': activeStep === 0,
      }"
    >
      <Button
        v-if="activeStep > 0"
        theme="secondary"
        @click="$emit('backward', activeStep - 1)"
      >
        Zurück
      </Button>
      <Button
        v-if="!showSendButton"
        theme="primary"
        class="ml-2"
        :disabled="nextDisabled"
        @click="$emit('forward', activeStep + 1)"
      >
        Weiter
      </Button>
      <Button
        v-else
        theme="primary"
        class="ml-2"
        :disabled="nextDisabled"
        @click="!loading ? $emit('submit') : null"
      >
        <template v-if="!loading">Abschicken</template>
        <template v-else>Lädt...</template>
      </Button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeStep: {
      type: Number,
      required: true,
    },
    nextDisabled: {
      type: Boolean,
      default: false,
    },
    showSendButton: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
