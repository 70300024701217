<template>
  <div class="w-80 mx-auto mb-16">
    <SelectField
      v-model="model.reply"
      label="Antwort auswählen"
      required
    >
      <option value="Ja, in den nächsten 6 Monaten.">Ja, in den nächsten 6 Monaten.</option>
      <option value="Ja, zu einem späteren Zeitpunkt.">Ja, zu einem späteren Zeitpunkt.</option>
      <option value="Nein, ich möchte mich nur informieren.">Nein, ich möchte mich nur informieren.</option>
    </SelectField>
    <TextField
      v-model="model.desiredPrice"
      label="Wunschpreis in €"
      type="number"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      model: {
        reply: null,
        desiredPrice: null,
      },
    };
  },

  watch: {
    model(newVal) {
      this.$emit('input', newVal);
    },
  },

  mounted() {
    this.model = this.value;
  },
};
</script>
