<template>
  <div class="w-full sm:w-64 mx-auto">
    <TextField
      v-model="model.livingSize"
      type="number"
      label="Wohnfläche in m²"
      :min="1"
      required
    />
    <TextField
      v-model="model.plotSize"
      type="number"
      label="Grundstücksfläche in m²"
      :min="1"
      required
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      model: {
        livingSize: null,
        plotSize: null,
      },
    };
  },

  watch: {
    model(newVal) {
      this.$emit('input', newVal);
    },
  },

  mounted() {
    this.model = {
      livingSize: this.value.livingSize > 0 ? this.value.livingSize : null,
      plotSize: this.value.plotSize > 0 ? this.value.plotSize : null,
    };
  },
};
</script>
