<template>
  <section class="mt-4">
    <!-- <Stepper
      :active="activeStep"
      :items="stepperItems"
      @change="(e) => activeStep = e"
    /> -->
    <div class="relative">
      <div class="w-full h-2 rounded-full bg-secondary-200" />
      <div
        class="bg-secondary-500 h-2 transition-all transition-fast rounded-full absolute top-0 left-0"
        :style="`width: ${((activeStep + 1) / (stepperItems.length)) * 100}%`"
      />
    </div>
    <FooterPanel
      :active-step="activeStep"
      :next-disabled="!stepperItems[activeStep].done"
      :show-send-button="activeStep === stepperItems.length - 1"
      :loading="loading"
      @backward="back"
      @forward="next"
      @submit="submit"
    />
    <div class="pt-4 md:pt-6 lg:pt-12">
      <h2 class="text-center font-medium text-2xl">{{ stepperItems[activeStep].label }}</h2>
    </div>
    <div class="flex pt-4">
      <ObjectType v-show="activeStep === 0" v-model="formValue.objectType" />
      <template
        v-if="(formValue.objectType !== null) && activeStep > 0"
      >
        <!-- Steps -->
        <HouseType
          v-if="formValue.objectType !== 'eigentumswohnung'"
          v-show="activeStep === 1"
          v-model="formValue[formValue.objectType].type"
        />
        <AppartmentType v-else v-show="activeStep === 1" v-model="formValue[formValue.objectType].appartmentType" />
        <AreaSizes
          v-if="formValue.objectType !== 'eigentumswohnung'"
          v-show="activeStep === 2"
          v-model="formValue[formValue.objectType].areaSizes"
        />
        <Floors
          v-show="isEigentumswohnung && activeStep === 2 || !isEigentumswohnung && activeStep === 3"
          v-model="formValue[formValue.objectType].floors"
          :is-appartment-complex="formValue.objectType === 'mehrfamilienhaus'"
          :is-appartment="formValue.objectType === 'eigentumswohnung'"
        />
        <YearOfBuild
          v-show="isEigentumswohnung && activeStep === 3 || !isEigentumswohnung && activeStep === 4"
          v-model="formValue[formValue.objectType].yearOfBuild"
        />
        <Furniture
          v-show="isEigentumswohnung && activeStep === 4 || !isEigentumswohnung && activeStep === 5"
          v-model="formValue[formValue.objectType].furniture"
        />
        <FurtherFurniture
          v-show="isEigentumswohnung && activeStep === 5 || !isEigentumswohnung && activeStep === 6"
          v-model="formValue[formValue.objectType].furtherFurniture"
        />
        <NeedForRenovation
          v-show="isEigentumswohnung && activeStep === 6 || !isEigentumswohnung && activeStep === 7"
          v-model="formValue[formValue.objectType].needForRenovation"
        />
        <Address
          v-show="isEigentumswohnung && activeStep === 7 || !isEigentumswohnung && activeStep === 8"
          v-model="formValue[formValue.objectType].address"
        />
        <ConsiderSelling
          v-show="isEigentumswohnung && activeStep === 8 || !isEigentumswohnung && activeStep === 9"
          v-model="formValue[formValue.objectType].considerSelling"
        />
        <PersonalData
          v-show="isEigentumswohnung && activeStep === 9 || !isEigentumswohnung && activeStep === 10"
          v-model="formValue[formValue.objectType].personalData"
        />
      </template>
    </div>
    <div v-if="activeStep === 0" class="flex justify-center items-center mt-4">
      <Icon name="check" class="text-2xl md:text-4xl text-secondary-500" />
      <div class="ml-2 font-medium text-secondary-900">
        Kostenlose Wertermittlung
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
// import Stepper from '../components/Stepper.vue';
import FooterPanel from '../components/FooterPanel.vue';
import ObjectType from './Form/ObjectType.vue';
import HouseType from './Form/HouseType.vue';
import AreaSizes from './Form/AreaSizes.vue';
import Floors from './Form/Floors.vue';
import YearOfBuild from './Form/YearOfBuild.vue';
import Furniture from './Form/Furniture.vue';
import FurtherFurniture from './Form/FurtherFurniture.vue';
import NeedForRenovation from './Form/NeedForRenovation.vue';
import Address from './Form/Address.vue';
import ConsiderSelling from './Form/ConsiderSelling.vue';
import PersonalData from './Form/PersonalData.vue';
import AppartmentType from './Form/AppartementType.vue';

export default {
  components: {
    // Stepper,
    FooterPanel,
    ObjectType,
    HouseType,
    AreaSizes,
    Floors,
    YearOfBuild,
    Furniture,
    FurtherFurniture,
    NeedForRenovation,
    Address,
    ConsiderSelling,
    PersonalData,
    AppartmentType,
  },

  data() {
    return {
      activeStep: 0,
      loading: false,

      formValue: {
        objectType: null,
        einfamilienhaus: {
          type: null,
          areaSizes: {
            livingSize: 0,
            plotSize: 0,
          },
          floors: {
            floors: 0,
            rooms: 0,
          },
          yearOfBuild: null,
          furniture: 'einfach',
          furtherFurniture: {
            garten: false,
            balkon: false,
            keller: false,
            garage: false,
            garageNumber: null,
            stellplatz: false,
            stellplatzNumber: null,
          },
          needForRenovation: 'nein',
          address: {
            street: null,
            houseNumber: null,
            zipCode: null,
            city: null,
          },
          considerSelling: {
            reply: null,
            desiredPrice: null,
          },
          personalData: {
            salutation: null,
            firstName: null,
            lastName: null,
            email: null,
            telephone: null,
            privacy: false,
          },
        },
        mehrfamilienhaus: {
          type: null,
          areaSizes: {
            livingSize: 0,
            plotSize: 0,
          },
          floors: {
            floors: 0,
            appartments: 0,
          },
          yearOfBuild: null,
          furniture: 'einfach',
          furtherFurniture: {
            garten: false,
            balkon: false,
            keller: false,
            garage: false,
            garageNumber: null,
            stellplatz: false,
            stellplatzNumber: null,
          },
          needForRenovation: 'nein',
          address: {
            street: null,
            houseNumber: null,
            zipCode: null,
            city: null,
          },
          considerSelling: {
            reply: null,
            desiredPrice: null,
          },
          personalData: {
            salutation: null,
            firstName: null,
            lastName: null,
            email: null,
            telephone: null,
            privacy: false,
          },
        },

        eigentumswohnung: {
          appartmentType: null,
          floors: {
            livingSize: 0,
            rooms: 0,
          },
          yearOfBuild: null,
          furniture: 'einfach',
          furtherFurniture: {
            garten: false,
            balkon: false,
            keller: false,
            garage: false,
            garageNumber: null,
            stellplatz: false,
            stellplatzNumber: null,
          },
          needForRenovation: 'nein',
          address: {
            street: null,
            houseNumber: null,
            zipCode: null,
            city: null,
          },
          considerSelling: {
            reply: null,
            desiredPrice: null,
          },
          personalData: {
            salutation: null,
            firstName: null,
            lastName: null,
            email: null,
            telephone: null,
            privacy: false,
          },
        },
      },
    };
  },

  computed: {
    stepperItems() {
      if (this.formValue.objectType === 'einfamilienhaus') {
        return [
          {
            label: 'Welche Objektart möchten Sie bewerten?',
            done: this.formValue.objectType === 'einfamilienhaus',
          },
          {
            label: 'Um welchen Haustyp handelt es sich?',
            done: ['freistehend', 'doppelhaushaelfte', 'reihenendhaus', 'reihenhaus'].indexOf(this.formValue.einfamilienhaus.type) !== -1,
          },
          {
            label: 'Wie gross ist Ihre Wohn- und Grundstücksfläche?',
            done: this.formValue.einfamilienhaus.areaSizes.livingSize > 0 && this.formValue.einfamilienhaus.areaSizes.plotSize > 0,
          },
          {
            label: 'Wie viele Etagen und Zimmer hat das Haus?',
            done: this.formValue.einfamilienhaus.floors.floors > 0 && this.formValue.einfamilienhaus.floors.rooms > 0,
          },
          {
            label: 'In welchem Jahr wurde das Haus gebaut?',
            done: this.formValue.einfamilienhaus.yearOfBuild >= 1840
              && this.formValue.einfamilienhaus.yearOfBuild <= new Date().getFullYear(),
          },
          {
            label: 'Wie würden Sie die Ausstattung Ihrer Immobilie beschreiben?',
            done: ['einfach', 'gehoben', 'luxuriös'].indexOf(this.formValue.einfamilienhaus.furniture) !== -1,
          },
          {
            label: 'Über welche Ausstattung verfügt Ihre Immobilie?',
            done: true,
          },
          {
            label: 'Ist Ihre Immobilie renovierungsbedürftig?',
            done: true,
          },
          {
            label: 'Wo befindet sich das Objekt?',
            done: this.formValue.einfamilienhaus.address.street && this.formValue.einfamilienhaus.address.houseNumber
              && this.formValue.einfamilienhaus.address.zipCode && this.formValue.einfamilienhaus.address.city,
          },
          {
            label: 'Erwägen Sie den Verkauf Ihrer Immobilie?',
            done: this.formValue.einfamilienhaus.considerSelling.reply,
          },
          {
            label: 'Wer soll die Auswertung erhalten?',
            done: this.formValue.einfamilienhaus.personalData.salutation && this.formValue.einfamilienhaus.personalData.firstName
              && this.formValue.einfamilienhaus.personalData.lastName && this.formValue.einfamilienhaus.personalData.email
              && this.formValue.einfamilienhaus.personalData.telephone && this.formValue.einfamilienhaus.personalData.privacy,
          },
        ];
      }

      if (this.formValue.objectType === 'mehrfamilienhaus') {
        return [
          {
            label: 'Welche Objektart möchten Sie bewerten?',
            done: this.formValue.objectType === 'mehrfamilienhaus',
          },
          {
            label: 'Um welchen Haustyp handelt es sich?',
            done: ['freistehend', 'doppelhaushaelfte', 'reihenendhaus', 'reihenhaus'].indexOf(this.formValue.mehrfamilienhaus.type) !== -1,
          },
          {
            label: 'Wie gross ist Ihre Wohn- und Grundstücksfläche?',
            done: this.formValue.mehrfamilienhaus.areaSizes.livingSize > 0 && this.formValue.mehrfamilienhaus.areaSizes.plotSize > 0,
          },
          {
            label: 'Wie viele Etagen und Wohnungen hat das Haus?',
            done: this.formValue.mehrfamilienhaus.floors.floors > 0 && this.formValue.mehrfamilienhaus.floors.appartments > 0,
          },
          {
            label: 'In welchem Jahr wurde das Haus gebaut?',
            done: this.formValue.mehrfamilienhaus.yearOfBuild >= 1840
              && this.formValue.mehrfamilienhaus.yearOfBuild <= new Date().getFullYear(),
          },
          {
            label: 'Wie würden Sie die Ausstattung Ihrer Immobilie beschreiben?',
            done: ['einfach', 'gehoben', 'luxuriös'].indexOf(this.formValue.mehrfamilienhaus.furniture) !== -1,
          },
          {
            label: 'Über welche Ausstattung verfügt Ihre Immobilie?',
            done: true,
          },
          {
            label: 'Ist Ihre Immobilie renovierungsbedürftig?',
            done: true,
          },
          {
            label: 'Wo befindet sich das Objekt?',
            done: this.formValue.mehrfamilienhaus.address.street && this.formValue.mehrfamilienhaus.address.houseNumber
              && this.formValue.mehrfamilienhaus.address.zipCode && this.formValue.mehrfamilienhaus.address.city,
          },
          {
            label: 'Erwägen Sie den Verkauf Ihrer Immobilie?',
            done: this.formValue.mehrfamilienhaus.considerSelling.reply,
          },
          {
            label: 'Wer soll die Auswertung erhalten?',
            done: this.formValue.mehrfamilienhaus.personalData.salutation && this.formValue.mehrfamilienhaus.personalData.firstName
              && this.formValue.mehrfamilienhaus.personalData.lastName && this.formValue.mehrfamilienhaus.personalData.email
              && this.formValue.mehrfamilienhaus.personalData.telephone && this.formValue.mehrfamilienhaus.personalData.privacy,
          },
        ];
      }

      if (this.formValue.objectType === 'eigentumswohnung') {
        return [
          {
            label: 'Welche Objektart möchten Sie bewerten?',
            done: this.formValue.objectType === 'eigentumswohnung',
          },
          {
            label: 'Welchen Wohnungstyp wollen Sie bewerten?',
            done: ['etagenwohnung', 'hochparterre', 'maisonette',
              'loft', 'penthouse', 'erdgeschosswohnung', 'dachgeschoss', 'souterrain']
              .indexOf(this.formValue.eigentumswohnung.appartmentType) !== -1,
          },
          {
            label: 'Wie gross ist Ihre Wohnfläche?',
            done: this.formValue.eigentumswohnung.floors.livingSize > 0 && this.formValue.eigentumswohnung.floors.rooms > 0,
          },
          {
            label: 'In welchem Jahr wurde das Wohnhaus gebaut?',
            done: this.formValue.eigentumswohnung.yearOfBuild >= 1840
              && this.formValue.eigentumswohnung.yearOfBuild <= new Date().getFullYear(),
          },
          {
            label: 'Wie würden Sie die Ausstattung Ihrer Wohnung beschreiben?',
            done: ['einfach', 'gehoben', 'luxuriös'].indexOf(this.formValue.eigentumswohnung.furniture) !== -1,
          },
          {
            label: 'Über welche Ausstattung verfügt Ihre Wohnung?',
            done: true,
          },
          {
            label: 'Ist Ihre Wohnung renovierungsbedürftig?',
            done: true,
          },
          {
            label: 'Wo befindet sich die Wohnung?',
            done: this.formValue.eigentumswohnung.address.street && this.formValue.eigentumswohnung.address.houseNumber
              && this.formValue.eigentumswohnung.address.zipCode && this.formValue.eigentumswohnung.address.city,
          },
          {
            label: 'Erwägen Sie den Verkauf Ihrer Wohnung?',
            done: this.formValue.eigentumswohnung.considerSelling.reply,
          },
          {
            label: 'Wer soll die Auswertung erhalten?',
            done: this.formValue.eigentumswohnung.personalData.salutation && this.formValue.eigentumswohnung.personalData.firstName
              && this.formValue.eigentumswohnung.personalData.lastName && this.formValue.eigentumswohnung.personalData.email
              && this.formValue.eigentumswohnung.personalData.telephone && this.formValue.eigentumswohnung.personalData.privacy,
          },
        ];
      }

      return [
        { label: 'Welche Objektart möchten Sie bewerten?', done: false },
        { label: 'Haustyp', done: false },
        { label: 'Wohn- und Grundstücksfläche', done: false },
        { label: 'Label 2', done: false },
        { label: 'Label 3', done: false },
        { label: 'Label 4', done: false },
        { label: 'Label 5', done: false },
        { label: 'Label 6', done: false },
        { label: 'Label 7', done: false },
        { label: 'Label 8', done: false },
        { label: 'Label 9', done: false },
      ];
    },
    isEigentumswohnung() {
      return this.formValue.objectType === 'eigentumswohnung';
    },
  },

  watch: {
    activeStep() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },

  methods: {
    back(i) {
      if (this.activeStep > 0) {
        this.activeStep = i;
      }
    },
    next(i) {
      if (this.stepperItems[i - 1].done) {
        this.activeStep = i;
      }
    },
    submit() {
      // const value = {
      //   objectType: this.formValue.objectType,
      //   formValue: this.formValue[this.formValue.objectType],
      // };
      this.loading = true;
      // TODO: add right url here
      axios.post(`${process.env.VUE_APP_SEND_API_URL}`, {
        objectType: this.formValue.objectType,
        formValue: this.formValue[this.formValue.objectType],
        identifier: process.env.VUE_APP_SEND_TO_EMAIL,
      }).then(() => {
        this.loading = false;
        this.$emit('submit', true);
      }).catch(() => {
        this.loading = false;
        this.$emit('submit', false);
      });
    },
  },
};
</script>
