<template>
  <div class="mx-auto mb-32">
    <SegmentControl
      v-model="model"
      :options="options"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      model: null,

      options: [
        { label: 'Ja', value: 'ja' },
        { label: 'Nein', value: 'nein' },
      ],
    };
  },

  watch: {
    model(newVal) {
      this.$emit('input', newVal);
    },
  },

  mounted() {
    this.model = this.value;
  },
};
</script>
