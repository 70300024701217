<template>
  <div class="flex w-80 flex-wrap mx-auto mb-32">
    <div class="flex w-full">
      <TextField
        v-model="model.street"
        class="w-4/6"
        label="Straße"
        required
      />
      <TextField
        v-model="model.houseNumber"
        label="Hausnr."
        class="ml-2 w-2/6"
        required
      />
    </div>
    <div class="flex w-full">
      <TextField
        v-model="model.zipCode"
        label="Postleitzahl"
        class="w-2/6"
        type="number"
        :min="1001"
        :max="99998"
        required
      />
      <TextField
        v-model="model.city"
        label="Stadt"
        class="ml-2 w-4/6"
        required
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      model: {
        street: null,
        houseNumber: null,
        zipCode: null,
        city: null,
      },
    };
  },

  watch: {
    model(newVal) {
      this.$emit('input', newVal);
    },
  },

  mounted() {
    this.model = this.value;
  },
};
</script>
