<template>
  <div class="inline-flex flex-wrap mx-auto justify-center">
    <BoxSelect v-model="model" select-value="freistehend">
      <img slot="img" src="../../assets/images/einfamilienhaus.png">
      Freistehend
    </BoxSelect>
    <BoxSelect v-model="model" select-value="doppelhaushaelfte">
      <img slot="img" src="../../assets/images/doppelhaushaelfte.png">
      Doppelhaushälfte
    </BoxSelect>
    <BoxSelect v-model="model" select-value="reihenendhaus">
      <img slot="img" src="../../assets/images/reihenendhaus.png">
      Reihenendhaus
    </BoxSelect>
    <BoxSelect v-model="model" select-value="reihenhaus">
      <img slot="img" src="../../assets/images/reihenhaus.png">
      Reihenhaus
    </BoxSelect>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      validator: value => [null, 'freistehend', 'doppelhaushaelfte', 'reihenendhaus', 'reihenhaus'].indexOf(value) !== -1,
      default: null,
    },
  },

  data() {
    return {
      model: null,
    };
  },

  watch: {
    model(newVal) {
      this.$emit('input', newVal);
    },
  },

  mounted() {
    this.model = this.value;
  },
};
</script>
