<template>
  <div class="inline-flex flex-wrap mx-auto justify-center">
    <BoxSelect v-model="model" select-value="etagenwohnung">
      Etagenwohnung
    </BoxSelect>
    <BoxSelect v-model="model" select-value="hochparterre">
      Hochparterre
    </BoxSelect>
    <BoxSelect v-model="model" select-value="maisonette">
      Maisonette
    </BoxSelect>
    <BoxSelect v-model="model" select-value="loft">
      Loft
    </BoxSelect>
    <BoxSelect v-model="model" select-value="penthouse">
      Penthouse
    </BoxSelect>
    <BoxSelect v-model="model" select-value="erdgeschosswohnung">
      Erdgeschosswohnung
    </BoxSelect>
    <BoxSelect v-model="model" select-value="dachgeschoss">
      Dachgeschoss
    </BoxSelect>
    <BoxSelect v-model="model" select-value="souterrain">
      Souterrain
    </BoxSelect>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      validator: value => [null, 'etagenwohnung', 'hochparterre', 'maisonette',
        'loft', 'penthouse', 'erdgeschosswohnung', 'dachgeschoss', 'souterrain'].indexOf(value) !== -1,
      default: null,
    },
  },

  data() {
    return {
      model: null,
    };
  },

  watch: {
    model(newVal) {
      this.$emit('input', newVal);
    },
  },

  mounted() {
    this.model = this.value;
  },
};
</script>
