<template>
  <div class="w-full sm:w-80 mx-auto mb-16">
    <TextField
      v-if="!isAppartment"
      v-model="model.floors"
      type="number"
      label="Anzahl Etagen (ohne Keller & Dachboden)"
      :min="1"
      required
    />
    <TextField
      v-if="!isAppartmentComplex"
      v-model="model.rooms"
      type="number"
      label="Anzahl Zimmer (ohne Bad & Küche)"
      :min="1"
      required
    />
    <TextField
      v-if="isAppartmentComplex"
      v-model="model.appartments"
      type="number"
      label="Anzahl Wohnungen"
      :min="1"
      required
    />
    <TextField
      v-if="isAppartment"
      v-model="model.livingSize"
      type="number"
      label="Wohnfläche in m²"
      :min="1"
      required
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    isAppartmentComplex: {
      type: Boolean,
      default: false,
    },
    isAppartment: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      model: {
        floors: null,
        rooms: null,
        appartments: null,
        livingSize: null,
      },
    };
  },

  watch: {
    model(newVal) {
      this.$emit('input', newVal);
    },
  },

  mounted() {
    if (this.isAppartmentComplex) {
      this.model = {
        floors: this.value.floors > 0 ? this.value.floors : null,
        appartments: this.value.appartments > 0 ? this.value.appartments : null,
      };
    } else if (!this.isAppartment) {
      this.model = {
        floors: this.value.floors > 0 ? this.value.floors : null,
        rooms: this.value.rooms > 0 ? this.value.rooms : null,
      };
    } else {
      this.model = {
        livingSize: this.value.livingSize > 0 ? this.value.livingSize : null,
        rooms: this.value.rooms > 0 ? this.value.rooms : null,
      };
    }
  },
};
</script>
