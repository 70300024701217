<template>
  <div class="w-full">
    <div class="flex flex-wrap mx-auto justify-center">
      <BoxSelect :value="model.garten" :select-value="true" @input="change('garten')">
        <img slot="img" src="../../assets/images/garten.png">
        Garten
      </BoxSelect>
      <BoxSelect :value="model.balkon" :select-value="true" @input="change('balkon')">
        <img slot="img" src="../../assets/images/terasse.png">
        Balkon / Terasse
      </BoxSelect>
      <BoxSelect :value="model.keller" :select-value="true" @input="change('keller')">
        <img slot="img" src="../../assets/images/keller.png">
        Keller
      </BoxSelect>
    </div>
    <div class="flex flex-wrap mx-auto justify-center">
      <div>
        <BoxSelect :value="model.garage" :select-value="true" @input="change('garage')">
          <img slot="img" src="../../assets/images/garage.png">
          <div>Garage / Carport</div>
        </BoxSelect>
        <TextField
          v-show="model.garage"
          ref="garageInput"
          v-model="model.garageNumber"
          class="mt-2 mx-2 w-40vw sm:w-auto sm:mx-4"
          :required="model.garage"
          type="number"
          placeholder="Anzahl Garagen / Carports"
        />
      </div>
      <div>
        <BoxSelect :value="model.stellplatz" :select-value="true" @input="change('stellplatz')">
          <img slot="img" src="../../assets/images/stellplatz.png">
          <div>Stellplatz</div>
        </BoxSelect>
        <TextField
          v-show="model.stellplatz"
          ref="stellplatzInput"
          v-model="model.stellplatzNumber"
          class="mt-2 mx-2 w-40vw sm:w-auto sm:mx-4"
          :required="model.stellplatz"
          type="number"
          placeholder="Anzahl Stellplätze"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      model: {
        garten: false,
        balkon: false,
        keller: false,
        garage: false,
        garageNumber: null,
        stellplatz: false,
        stellplatzNumber: null,
      },
    };
  },

  computed: {
    garageModel() {
      return this.model.garage;
    },
    stellplatzModel() {
      return this.model.stellplatz;
    },
  },

  watch: {
    garageModel(newVal) {
      if (newVal) this.model.garageNumber = 1;
      else this.model.garageNumber = null;
    },
    stellplatzModel(newVal) {
      if (newVal) this.model.stellplatzNumber = 1;
      else this.model.stellplatzNumber = null;
    },
  },

  mounted() {
    this.model.garten = this.value.garten;
    this.model.balkon = this.value.balkon;
    this.model.keller = this.value.keller;
    this.model.garage = this.value.garage;
    this.model.garageNumber = this.value.garageNumber;
    this.model.stellplatz = this.value.stellplatz;
    this.model.stellplatzNumber = this.value.stellplatzNumber;
  },

  methods: {
    change(e) {
      this.model[e] = !this.model[e];
      this.$emit('input', this.model);
    },
  },
};
</script>
