<template>
  <div class="pt-6 pb-4 lg:pb-6">
    <div class="flex items-center flex-wrap sm:flex-no-wrap">
      <img
        src="../assets/logo.png"
        alt="Cuxlandimmo"
        class="h-16"
      >
      <div class="w-full h-px sm:w-px sm:h-12 my-2 sm:my-0 sm:mx-4 bg-gray-400 font-bold" />
      <div>
        <h2 class="font-bold text-secondary-900 leading-tight text-2xl">Kostenlose Wertermittlung</h2>
        <span class="text-sm uppercase font-medium leading-none tracking-wide text-gray-600">Immobilienbewertung leicht gemacht.</span>
      </div>
    </div>
    <Modal v-model="open">
      <template v-if="success">
        <div class="text-center w-64 max-w-full">
          <Icon name="valid" class="text-green-500 text-6xl" />
          <div class="text-xl font-bold">Ihre Anfrage wurde gesendet!</div>
          <p class="text-gray-700 mt-2">
            Wir haben Ihre Anfrage erhalten und erstellen jetzt die Marktwertanalyse Ihres Objektes.<br>
            Sollten wir Fragen haben, werden wir auf Sie zukommen.
          </p>
          <div class="flex justify-center mt-4">
            <Button @click="closeModal">Schließen</Button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text-center w-64 max-w-full">
          <Icon name="valid" class="text-red-500 text-6xl" />
          <div class="text-xl font-bold">Fehler beim Senden Ihrer Anfrage!</div>
          <p class="text-gray-700 mt-2">
            Bitte versuchen Sie es zu einem späteren Zeitpunkt nochmal oder kontaktieren Sie uns unter
            <a
              class="text-secondary-600 hover:underline"
              target="_blank"
              href="mailto:info@cuxlandimmo.com?bcc=it@gridventures.de&amp;subject=Fehler%20im%20Bewertung%20Formular"
            >info(at)cuxlandimmo.com</a>.
          </p>
          <div class="flex justify-center mt-4">
            <Button @click="closeModal">Schließen</Button>
          </div>
        </div>
      </template>
    </Modal>
    <div>
      <Form @submit="openModal" />
    </div>
  </div>
</template>

<script>
import Form from './Form.vue';
import Modal from '../components/Modal.vue';

export default {
  components: {
    Form,
    Modal,
  },

  data() {
    return {
      open: false,
      success: false,
    };
  },

  methods: {
    openModal(e) {
      this.open = true;
      this.success = e;
    },
    closeModal() {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
  },
};
</script>
