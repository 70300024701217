<template>
  <div class="w-80 mx-auto">
    <SelectField
      v-model="model.salutation"
      label="Anrede"
      required
    >
      <option>Herr</option>
      <option>Frau</option>
    </SelectField>
    <TextField
      v-model="model.firstName"
      label="Vorname"
      required
    />
    <TextField
      v-model="model.lastName"
      label="Nachname"
      required
    />
    <TextField
      v-model="model.email"
      label="E-Mail Adresse"
      type="email"
      required
    />
    <TextField
      v-model="model.telephone"
      label="Telefonnummer"
      type="tel"
      required
    />
    <div>
      <input
        id="checkPrivacy"
        v-model="model.privacy"
        type="checkbox"
        required="true"
      >
      <label for="checkPrivacy" class="ml-2 cursor-pointer">
        Ich habe die <a href="https://cuxland-immo.de/datenschutzerklaerung/" class="text-secondary-600 underline" target="_blank">Datenschutzerklärung</a> gelesen und akzeptiere sie.
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      model: {
        salutation: null,
        firstName: null,
        lastName: null,
        email: null,
        telephone: null,
        privacy: false,
      },
    };
  },

  watch: {
    model(newVal) {
      this.$emit('input', newVal);
    },
  },

  mounted() {
    this.model = this.value;
  },
};
</script>
