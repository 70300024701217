<template>
  <div class="inline-flex flex-wrap mx-auto justify-center">
    <BoxSelect v-model="model" select-value="einfamilienhaus">
      <img slot="img" src="../../assets/images/einfamilienhaus.png">
      Einfamilienhaus
    </BoxSelect>
    <BoxSelect v-model="model" select-value="mehrfamilienhaus">
      <img slot="img" src="../../assets/images/mehrfamilienhaus.png">
      Mehrfamilienhaus
    </BoxSelect>
    <BoxSelect v-model="model" select-value="eigentumswohnung">
      <img slot="img" src="../../assets/images/eigentumswohnung.png">
      Eigentumswohnung
    </BoxSelect>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      validator: value => [null, 'einfamilienhaus', 'mehrfamilienhaus', 'eigentumswohnung'].indexOf(value) !== -1,
      default: null,
    },
  },

  data() {
    return {
      model: null,
    };
  },

  watch: {
    model(newVal) {
      this.$emit('input', newVal);
    },
  },

  mounted() {
    this.model = this.value;
  },
};
</script>
